import moment from 'moment-timezone';

export const IconDisplayStyles = [
  { id: 1, status: 1 },
  { id: 0, status: 1 },
  { id: 2, status: 1 },
  { id: 0, status: 1 },
  { id: 4, status: 1 },
  { id: 0, status: 1 },
  { id: 6, status: 1 }
];

export const trackingMap = [
  [10, 'All'],
  [1, 'Pending'],
  [2, 'In transit'],
  [4, 'Pick up'],
  [6, 'Delivered'],
  [3, 'Expired'],
  [9, 'Attention']
];

const orderStatusMap = {
  // '1': 'Pending',
  // '2': 'Awaiting order',
  // '3': 'Awaiting payment',
  '4': 'Awaiting shipment',
  '5': 'Fulfilled',
  '6': 'Canceled',
  // '7': 'Failed orders',
  '8': 'Awaiting fulfillment'
};

const aeOrderStatus = [
  'WAIT_SELLER_SEND_GOODS',
  'WAIT_BUYER_ACCEPT_GOODS',
  'IN_CANCEL',
  'SELLER_PART_SEND_GOODS'
];

const statusMap = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 4, value: 3 },
  { key: 8, value: 4 },
  { key: 2048, value: 5 },
  { key: 16, value: 6 },
  { key: 32, value: 7 }
];

export const getTrackingShowData = (order, timezoneRegion, tabsType) => {
  const arr = [];
  order?.forEach(element => {
    const aeID = [];
    const aeID2 = []
    const aeData = [];
    const orderStatusArr = [];
    let orderStatus = '';
    const {
      detail: { name, created_at, line_items, id },
      ext: { lineitems, transactions, address },
      store_id
    } = element;

    lineitems?.forEach(it => {
      if (it?.sp?.length) {
        it?.sp?.forEach(item => {
          let traData = '';
          transactions?.forEach(ele => {
            if (ele?.order_info && ele?.order_info[item?.platform_order_id]) {
              traData = ele?.order_info[item?.platform_order_id];
            }
          });

          let flag = false;

          let pending = false;

          item?.logistics_infos?.forEach(element => {
            console.log(element.logistics_status === 1);
            if (element.logistics_status === 1) {
              pending = true;
            }
          });

          if (tabsType === '1' && (!item?.logistics_infos || pending)) {
            flag = true;
          } else if (tabsType === '2') {
            item?.logistics_infos?.forEach(element => {
              if (element.logistics_status === 2) {
                flag = true;
              }
            });
          } else if (tabsType === '4') {
            item?.logistics_infos?.forEach(element => {
              if (element.logistics_status === 4) {
                flag = true;
              }
            });
          } else if (tabsType === '6') {
            item?.logistics_infos?.forEach(element => {
              if (element.logistics_status === 6) {
                flag = true;
              }
            });
          } else if (tabsType === '3') {
            item?.logistics_infos?.forEach(element => {
              if (element.logistics_status === 3) {
                flag = true;
              }
            });
          } else if (tabsType === '9') {
            if (item.attention === 1 && (!item?.logistics_infos || pending)) {
              flag = true;
            } else if (item.attention === 2) {
              item?.logistics_infos?.forEach(element => {
                if ([5, 7, 8].includes(element.logistics_status)) {
                  flag = true;
                }
              });
            }
          } else if (tabsType === '10') {
            if (!item?.logistics_infos || pending) {
              flag = true;
            }
            if (item.attention === 1 && (!item?.logistics_infos || pending)) {
              flag = true;
            } else if (item.attention === 2) {
              item?.logistics_infos?.forEach(element => {
                if ([5, 7, 8].includes(element.logistics_status)) {
                  flag = true;
                }
              });
            }
            item?.logistics_infos?.forEach(element => {
              if ([2, 4, 6, 3].includes(element.logistics_status)) {
                flag = true;
              }
            });
          }
          if (!item.platform_order_id) {
            flag = false;
          }
          if (flag) {
            if (
              item.platform_order_id &&
              !aeID.includes(item.platform_order_id)
            ) {
              aeID.push(item.platform_order_id);
              aeID2.push({
                id:item.platform_order_id,
                supply_platform:it?.platform_type || 2
              })
            }

            let obj = {
              trackingNo: item?.logistics_infos,
              title: item?.title,
              variant_title: item?.variant_title || 'Default Title',
              quantity: item?.quantity,
              store_name: traData?.store_info?.store_name,
              store_url: traData?.store_info?.store_url,
              is_default: item.is_base,
              attention: item.attention,
              map_type: it.supplier_map_type,
              supply_platform:it?.platform_type || 2
            };

            aeData.push(obj);
          }

          if (![2,3,7,].includes(item.status)) {
            statusMap?.forEach(eleMap => {
              if (it.status & eleMap.key) {
                let flag =
                  item.status === 4 &&
                  item.logistics_infos &&
                  item.logistics_infos.length;

                  flag = item.status === 5 && (it.status & eleMap.key) === 8;
 
                if (flag) {
                  orderStatusArr.push(orderStatusMap[eleMap.value + 4]);
                } else if (orderStatusMap[eleMap.value]) {
                  orderStatusArr.push(orderStatusMap[eleMap.value]);
                }
              }
            });
          }


        });
      }
    });

    if (orderStatusArr?.length) {
      Array.from(new Set(orderStatusArr)).forEach((ele, index) => {
        if (index === 0) {
          orderStatus = ele;
        } else {
          orderStatus += ` + ${ele}`;
        }
      });
    }

    let date = moment(created_at).format('HH:mm YYYY-MM-DD');

    // 订单时间 如果设置了时区 把时间挪到那个时区
    if (timezoneRegion) {
      date = moment(created_at)
        .tz(timezoneRegion)
        .format('HH:mm YYYY-MM-DD');
    }

    if (aeData.length) {
      let obj = {
        orderNumber: name?.indexOf('#') == 0 ? name.substring(1) : name,
        shopifyId: id,
        date,
        aeID2,
        aeID,
        aeData,
        orderStatus,
        store_id: store_id,
        contact_person: address?.contact_person,
        email: address?.email,
        address: `${address?.address || ''} ${address?.address2 || ''}`,
        position: `${address?.country || ''} ${address?.province ||
          ''} ${address?.city || ''}`
      };

      arr.push(obj);
    }

  });

  console.log(arr);

  return arr;
};
