import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef
} from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { connect } from 'dva';
import classnames from 'classnames';
import timezoneKu from 'common/timezone';
import {
  Input,
  Select,
  DatePicker,
  Table,
  Icon,
  message,
  Tooltip,
  Divider,
  Tabs,
  List,
  Card,
  Dropdown,
  Menu,
  Radio
} from 'antd';

import styles from './index.less';
import orderStyles from '../Order/Order.less';
import '../Order/Order.css'
import timezone from '../../common/timezone';
import { GuildeAction, LinkStoreAction } from 'actions'
import radioStyles from '../Order/FilterSearch/FilterSearch.less'

import iconAe from '../../assets/icon-ae.jpg';
import iconTmall from '../../assets/tmall.svg';

import LogisticsDetails from './component/LogisticsDetails';

import FilterSearch from '../Order/FilterSearch';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import FilterHeader from 'components/FilterHeader';
import InputGuide from 'components/FilterDrawer/InputGuide'

import OnlyPagination from 'components/OnlyPagination';

import { Post } from 'utils/request';

import debounce from 'lodash/debounce';

import { getTrackingShowData, trackingMap } from './util';
import { element } from 'prop-types';

import { CommonDataCollect } from 'utils/utils'

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

import './tracking.css';

const Tracking = ({ userInfo, dispatch, storeIdWithOrder, timezoneRegion,storeId,trackingListProp,plan }) => {
  const [trackingNo, setTrackingNo] = useState('');
  const [trackingData, setTrackingData] = useState({});
  const [deleteSearch, setDeleteSearch] = useState(false);
  const [dataType, setDataType] = useState([
    {
      type:1,
      timestamp:0,
      name:'Today'
    },
    {
      type:2,
      timestamp:7,
      name:'Last 7 days'
    },
    {
      type:3,
      timestamp:30,
      name:'Last 30 days'
    },
    {
      type:4,
      timestamp:90,
      name:'Last 90 days'
    },
    {
      type:5,
      timestamp:180,
      name:'Last 180 days'
    }
  ])
  const [dateRadioDefaultType, setDateRadioDefaultType] = useState(3) 


  const [startDatetime, setStartDatetime] = useState(
    moment()
      .subtract(30, 'days')
      .startOf('day')
      .unix()
  );
  const [endDatetime, setEndDatetime] = useState(
    moment()
      .endOf('day')
      .unix()
  );


  const [searchFlag, setSearchFlag] = useState(false)
  const [searchFlagBtn, setSearchFlagBtn] = useState(false)
  const [hidden, setHidden] = useState(true)
  const [drawerVisiable, setDrawerVisiable] = useState(false)
  const inputRef = useRef(null)

  const [ flag1, setFlag1 ] = useState(true)
  const [ flag2, setFlag2 ] = useState(false)

  const [searchType, setSearchType] = useState(1); // 搜索类型 搜订单号 还是产品名
  const [listLength, setListLength] = useState(0); // 这一页订单数量
  const [cursor, setCursor] = useState({
    1: ''
  }); // 翻页游标
  const [currentPage, setCurrentPage] = useState(1); // 当前页
  const [pageSize, setPageSize] = useState(20); // 一页多少条
  const [count, setCount] = useState(0); // 订单总个数
  const [searchKey, setSearchKey] = useState(''); // 搜索内容
  const [currentTimezone, setCurrentTimezone] = useState(timezoneRegion || Intl.DateTimeFormat().resolvedOptions().timeZone); // 当前时区
  const [momentFormatTime, setMomentFormatTime] = useState(undefined);
  const [tabsType, setTabsType] = useState('10');
  const [trackSet,setTrackSet] = useState([]);
  const [trackStore,setTrackStore] = useState(true);
  const [sDate, setSDate] = useState(
    `${moment()
      .subtract(30, 'days')
      .year()}-${moment()
      .subtract(30, 'days')
      .month() + 1}-${moment()
      .subtract(30, 'days')
      .date()}`
  );
  const [eDate, setEDate] = useState(
    `${moment().year()}-${moment().month() + 1}-${moment().date()}`
  );
  const timeZoneRef = useRef(null)
  const request = useRef(false);
  const [cacheOrdersStoreId, setCacheOrdersStoreId] = useState(
    localStorage.getItem('currentOrdersStoreId') || 'all'
  );
  const [trackingList, setTrackingList] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [countObj, setCountObj] = useState({
    all: 0,
    attention: 0,
    delivered: 0,
    expired: 0,
    in_transit: 0,
    pending: 0,
    pick_up: 0
  });

  const [fulfilled, setFulfilled] = useState(5);

  const cancelRef = useRef({
    timeZone:timezoneRegion,
    fulfilled:5,
    startDatetime:startDatetime,
    endDatetime:endDatetime,
    sDate:sDate,
    eDate:eDate,
    changeTime:false //控制3隐藏显示
  })

  const cancelFuilTag = useRef({fulfilled:5})
  const cancelTime = useRef({sDate:'',eDate:''})
  const cancelTimeZone = useRef({timeZone:''})
  const selectDate = useRef(false)
  const selectTime = useRef({
    st: startDatetime,
    et:endDatetime
  })

  useEffect(() => {

    const diffMinute =
      moment()
        .tz(cancelRef.current.timeZone || timezoneRegion || Intl.DateTimeFormat().resolvedOptions().timeZone)
        .utcOffset() - moment().utcOffset();

    let firstTimeS = moment(cancelRef.current.sDate).unix() - diffMinute * 60
    let firstTimeE = moment(cancelRef.current.eDate)
    .endOf('day')
    .unix() -
    diffMinute * 60

    localStorage.setItem('trackAction',JSON.stringify({
      timeZone:timezoneRegion,
      fulfilled:fulfilled,
      startDatetime:firstTimeS,
      endDatetime:firstTimeE,
      sDate:sDate,
      eDate:eDate
    }))


    let isMove = true;
    var dom = document.getElementById("root");
    //按下标识
    let mouseFlag = false;

    dom.onclick = e => {
      let con = document.querySelector('#order_no_input_box')
      let con1 = document.querySelector('#filterText')
      let con2 = document.querySelector('#filterTextIcon')
      let searchKey = document.querySelector('#searchKey')
      console.log(e.target)
      // if(e.target.getAttribute('class') == 'ant-tooltip-open'){
      //   setSearchFlag(true)
      //   return
      // }
      if(e.target.getAttribute('id') == 'searchKey'){
        setSearchFlag(true)
        return
      }

      if(typeof con?.contains === 'function'){
        if(e.target == con1 || e.target == con2){
          setSearchFlag(true)
          return
        }
        if (!con?.contains(e.target) && isMove && !searchKey) {
          setSearchFlag(false)
          if(e.target.getAttribute('id') != 'filterText' && e.target.getAttribute('class') != 'filterBox'){
            setTimeout(()=>{
            setSearchFlagBtn(false)
            setHidden(true)
          },600)
          }
        }
      }
    }

    dom.onmousemove = e => {
      if(mouseFlag){
        isMove = false
      }
    }

    dom.onmousedown = (e) => {
      mouseFlag = true
    }

    dom.onmouseup = (e) => {
      mouseFlag = false
      setTimeout(()=>{
        isMove = true
      },800)
    }

  },[])


  const getStores = stores => {
    let flag;
    userInfo?.stores?.forEach(item => {
      if (item.id === stores) {
        flag = item.domain;
      }
    });
    return flag;
  };

  const getCursor = Orders => {
    let obj = {
      ...cursor,
      [currentPage + 1]: Orders.cursor
    };
    setCursor(obj);
    setCount(Orders?.count);
    setListLength(Orders?.orders?.length);
  };


  const getTrackingList = async (flags = true) => {
    // if (request.current) {
    //   return;
    // }
    console.log(searchKey);
    request.current = true;
    setLoadingState(true);

    if(selectDate.current){
      const params = {
        size: pageSize,
        cursor: cursor[currentPage],
        keywords: encodeURIComponent(searchKey),
        search_type: searchType,
        start_at: selectTime.current.st,
        end_at: selectTime.current.et,
        status: cancelRef.current.hasOwnProperty('fulfilled') ?  cancelRef.current.fulfilled : fulfilled,
        logistics_status: Number(tabsType)
      };
      let res = await Post('/ord/list', {
        data: params
      });
      if (res.code === 2000) {
        window.DSERS_COLLECT({
          action:'logistics_tracking',
          custom_info: [
            {
              name:'GET_COUNT_BY_LOGISTICS_USES',
              value:'1'
            }
          ]
        });

        if(res?.data?.Orders?.orders){
          window.DSERS_COLLECT({
            action:'logistics_tracking',
            custom_info: [
              {
                name:'GET_COUNT_BY_LOGISTICS_SUCCESS',
                value:'success'
              }
            ]
          });
        }else{
          window.DSERS_COLLECT({
            action:'logistics_tracking',
            custom_info: [
              {
                name:'GET_COUNT_BY_LOGISTICS_ERROR',
                value:'error'
              }
            ]
          });
        }

        let result = await Post('/ord/countByLogistics', {
          data: params
        });
        if (result.code === 2000) {
          setCountObj(result.data);
          setLoadingState(false);
          request.current = false;
          getCursor(res.data.Orders); // 这是当前状态订单数量 设置翻页游标 翻页用
          let list = [];
          if (res?.data?.Orders?.orders?.length) {
            list = getTrackingShowData(
              res?.data?.Orders?.orders,
              timezoneRegion,
              tabsType
            );
          }
          setTrackingList(list);

        }
      }
      return
    }
    //覆盖默认的逻辑
    if(flags){
      let cancelData = JSON.parse(localStorage.getItem('trackAction'))
      let dataObj = dataType.find(item => item.type == dateRadioDefaultType)
      if(dateRadioDefaultType == 1){
        cancelRef.current.startDatetime = parseInt(moment().startOf('day').valueOf() / 1000)
        cancelRef.current.endDatetime = parseInt(moment().endOf('day').valueOf() / 1000)
      }
      if(dateRadioDefaultType == 2){
        cancelRef.current.startDatetime = parseInt(moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        cancelRef.current.endDatetime = parseInt(moment().valueOf() / 1000)
      }
      if(dateRadioDefaultType == 3){
        cancelRef.current.startDatetime = parseInt(moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        cancelRef.current.endDatetime = parseInt(moment().valueOf() / 1000)
      }
      if(dateRadioDefaultType == 4){
        cancelRef.current.startDatetime = parseInt(moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        cancelRef.current.endDatetime = parseInt(moment().valueOf() / 1000)
      }
      if(dateRadioDefaultType == 5){
        cancelRef.current.startDatetime = parseInt(moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        cancelRef.current.endDatetime = parseInt(moment().valueOf() / 1000)
      }
    }

    try {
      const params = {
        size: pageSize,
        cursor: cursor[currentPage],
        keywords: encodeURIComponent(searchKey),
        search_type: searchType,
        start_at: cancelRef.current.hasOwnProperty('startDatetime') ?  cancelRef.current.startDatetime :startDatetime,
        end_at: cancelRef.current.hasOwnProperty('endDatetime') ?  cancelRef.current.endDatetime : endDatetime,
        status: cancelRef.current.hasOwnProperty('fulfilled') ?  cancelRef.current.fulfilled : fulfilled,
        logistics_status: Number(tabsType)
      };
      let res = await Post('/ord/list', {
        data: params
      });
      if (res.code === 2000) {
        window.DSERS_COLLECT({
          action:'logistics_tracking',
          custom_info: [
            {
              name:'GET_COUNT_BY_LOGISTICS_USES',
              value:'1'
            }
          ]
        });

        if(res?.data?.Orders?.orders){
          window.DSERS_COLLECT({
            action:'logistics_tracking',
            custom_info: [
              {
                name:'GET_COUNT_BY_LOGISTICS_SUCCESS',
                value:'success'
              }
            ]
          });
        }else{
          window.DSERS_COLLECT({
            action:'logistics_tracking',
            custom_info: [
              {
                name:'GET_COUNT_BY_LOGISTICS_ERROR',
                value:'error'
              }
            ]
          });
        }

        let result = await Post('/ord/countByLogistics', {
          data: params
        });
        if (result.code === 2000) {
          setCountObj(result.data);
          setLoadingState(false);
          request.current = false;
          getCursor(res.data.Orders); // 这是当前状态订单数量 设置翻页游标 翻页用
          let list = [];
          if (res?.data?.Orders?.orders?.length) {
            list = getTrackingShowData(
              res?.data?.Orders?.orders,
              timezoneRegion,
              tabsType
            );
          }
          setTrackingList(list);

        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 删除搜索内容
  const deleteSearchKey = () => {
    setSearchKey('');
    // changeSearch();
  };

  // 点击搜索
  const changeSearch = () => {
    setCurrentPage(1);
    // setSearchFlag(false)
    if (currentPage === 1) {
      getTrackingList();
    }
  };

  // 改变页码
  const changePager = flag => {
    let currentPages = flag === 'prev' ? currentPage - 1 : currentPage + 1;
    setCurrentPage(currentPages);
    goTop();
  };

  // 改变一页显示数量
  const changePageSize = v => {
    setPageSize(parseInt(v));
    setCurrentPage(1);
    setCursor({
      1: ''
    });
    goTop();
  };

  const goTop = () => {
    const anchorElement = document.getElementById('trackingBox');
    if (anchorElement) {
      anchorElement.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  };

  // 改变商店
  const changeStore = value => {
    localStorage.setItem('currentOrdersStoreId', value);
    changeSearch();
  };
  useEffect(()=>{
    if(!Array.isArray(trackSet) || !trackSet.length){
      return;
    }
    setTrackStore(true)
    if(storeId == "all" && trackSet.every(item=> !item.status)){
           setTrackStore(false)
           return
    }
    trackSet.forEach((item,index)=>{
      if(item.store_id == storeId && !item.status){
           setTrackStore(false)
      } 
    })
    },[storeId,trackSet])

  useEffect(() => {
    changeStore(storeIdWithOrder);
  }, [storeIdWithOrder]);

  useEffect(() => {
    const stores = userInfo.stores || [];
    let oneStore = null;
    let oneStoreId = 'all';
    oneStore = stores.find(store => cacheOrdersStoreId === store.id);
    oneStoreId = oneStore ? oneStore.id : oneStoreId;

    localStorage.setItem('currentOrdersStoreId', oneStoreId);

    if (oneStoreId !== storeIdWithOrder) {
      dispatch({
        type: 'global/updateOrderStoreId',
        payload: oneStoreId
      });
      return;
    }
  }, []);

  // 修改数据后重新搜索
  useEffect(() => {
    getUTCStartEnd(timezoneRegion)
    getTrackingList();
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'browse_tracking_tab',
    })
  }, [currentPage, pageSize, tabsType]);

  useEffect(() => {
    getTrackingSetting()
    dispatch({ type: 'global/countDown', payload: true });
  }, []);

  const getTrackingSetting = () =>{
        if(trackingListProp) {
          setTrackSet([...trackingListProp])
        }else{
          dispatch({
            type: 'dropshippersetting/getTrackingList',
            payload: {
              callback: d => {
                if(d.code == 2000 && d.data && Array.isArray(d.data)){
                  setTrackSet([...d.data])
                }
               
              }
            }
          });
        }
  }
  // 选择时间
  const changeDatetime = m => {
    setSDate(`${m[0].year()}-${m[0].month() + 1}-${m[0].date()}`);
    setEDate(`${m[1].year()}-${m[1].month() + 1}-${m[1].date()}`);

    setMomentFormatTime(m);
    setCurrentPage(1);

    if (!(currentTimezone === undefined)) {
      getUTCStartEnd(currentTimezone);
      return;
    }
    setStartDatetime(m[0].startOf('day').unix());
    setEndDatetime(m[1].endOf('day').unix());
  };

  // 获取用户选择的时区的开始结束时间
  const getUTCStartEnd = (zone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    // console.log(598);
    const diffMinute =
      moment()
        .tz(cancelRef.current.timeZone || zone)
        .utcOffset() - moment().utcOffset();
    setStartDatetime(moment(cancelRef.current.sDate).unix() - diffMinute * 60);
    setEndDatetime(
      moment(cancelRef.current.eDate)
        .endOf('day')
        .unix() -
        diffMinute * 60
    );

    cancelRef.current.startDatetime = moment(cancelRef.current.sDate).unix() - diffMinute * 60
    cancelRef.current.endDatetime = moment(cancelRef.current.eDate).endOf('day').unix() - diffMinute * 60
    

    console.log('cancelRef', cancelRef)

    
  };

  // 改时区
  const changeUTC = v => {
    if (v === undefined) {
      localStorage.removeItem(`timezone${userInfo.user_id}`);
      localStorage.removeItem(`timezoneV${userInfo.user_id}`);
      setCurrentTimezone(undefined);

      if (momentFormatTime) {
        setStartDatetime(
          momentFormatTime[0] ? momentFormatTime[0].startOf('day').unix() : ''
        );
        setEndDatetime(
          momentFormatTime[1] ? momentFormatTime[1].endOf('day').unix() : ''
        );
      } else {
        setStartDatetime(
          moment()
            .subtract(30, 'days')
            .startOf('day')
            .unix()
        );
        setEndDatetime(
          moment()
            .endOf('day')
            .unix()
        );
      }
      setCurrentPage(1);
      return;
    }

    setCurrentTimezone(v);
    localStorage.setItem(`timezone${userInfo.user_id}`, v); // 用户选的时区要存下来 下次登陆还用这个
    getUTCStartEnd(v);
  };

  // 关闭物流详情弹框
  const handleClose = useCallback(() => {
    setTrackingNo('');
    setTrackingData({});
    getTrackingList();
  }, [
    searchKey,
    pageSize,
    cursor,
    currentPage,
    searchType,
    startDatetime,
    endDatetime,
    fulfilled,
    tabsType
  ]);

  // 打开物流详情弹框
  const openClose = useCallback((item, logistics_no) => {

    window.DSERS_COLLECT({
      action:'logistics_tracking',
      custom_info: [
        {
          name:'CLICK_TRACKING_NO',
          value:'true'
        }
      ]
    });

    setTrackingNo(logistics_no);
    setTrackingData(item);
  }, []);

  const changeSearchKey = e => {
    // 输入搜索内容
    if (e.target.value) {
      setDeleteSearch(true);
    }
    setSearchKey(e.target.value);
  };

  // 鼠标经过搜索框是否展示删除图标
  const showDeleteSearch = () => {
    // 鼠标经过搜索框是否展示删除图标
    if (searchKey) {
      setDeleteSearch(true);
    }
  };

  // 鼠标离开搜索框隐藏删除图标
  const hideDeleteSearch = () => {
    setDeleteSearch(false);
  };

  // 搜索类型下拉
  const searchMoreFilter = () => {
    return (
      <Select
        placeholder="Filter"
        dropdownMatchSelectWidth={false}
        style={{
          width: 104
        }}
        onChange={e => setSearchType(e)}
        defaultValue={1}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        suffixIcon={
          <img
            src={require('../../assets/newImportList/icon_expand_more-24px.png')}
          ></img>
        }
      >
        <Option value={1}>{intl.get('tracking.order_no')} </Option>
        <Option value={5}>{intl.get('tracking.email')}</Option>
        <Option value={7}>{intl.get('tracking.tracking_no')}</Option>
      </Select>
    );
  };

  //
  const getOrderTabTitle = item => {
    const statusToCount = {
      10: 'all',
      1: 'pending',
      2: 'in_transit',
      4: 'pick_up',
      6: 'delivered',
      3: 'expired',
      9: 'attention'
    };
    const num = countObj[statusToCount[`${item[0]}`]]; // 当前搜索条件下 这个状态有多少订单

    return (
      <>
        {intl.getHTML('tracking.trackingMap', {
          key: item[0]
        })}
        {item[0] !== 10 ? (
          <div className={styles.center}>{`(${num})`}</div>
        ) : (
          <div className={styles.center}>&nbsp;</div>
        )}
      </>
    );
  };

  const menu = (ele, trackingNo) => (
    <Menu>
      {trackingNo.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => openClose(ele, item.logistics_no)}
        >
          <Tooltip placement="top" title={item.logistics_no}>
            {item.logistics_no}
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );

  // 展示更多Tracking
  const moreTracking = (item, trackingNo) => {
    if (trackingNo?.length > 1) {
      return (
        <Dropdown
          overlay={menu(item, trackingNo)}
          trigger={['hover']}
          overlayClassName={styles.dropdown}
        >
          <div className={styles.more}>···</div>
        </Dropdown>
      );
    }
    return null;
  };

  const searchFilterOrder = (type) => {

    if(type == 'search'){
      return <>
      <div className='inputBoxSearch'>
        <div className="filterBox" onClick={() => {
          setSearchFlag(true)
          setSearchFlagBtn(true)
          setTimeout(()=>{
            setHidden(false)
          },600)
          setTimeout(()=>{
            inputRef.current.focus();
          },0)
        }}>
           <i id="filterTextIcon" class="material-icons notranslate">search</i>
              <span id="filterText" className='filterText'>{intl.get('order.search_type.searchOrder')}</span>
        </div>
        {
          searchFlag ? null : 
          <div className='inputBox'>
            <InputGuide />
          </div>
        }
        </div>
      </>
    }
    if(type == 'filter'){
      return <>
        <div className='filterBox' onClick={() => {
          setDrawerVisiable(true)
        }}>
          <i class="material-icons notranslate">filter_alt</i>
          <span className='filterText'>{intl.get('import_list.filter')}</span>
        </div>
      </>
    }
  }

  const onChangeTime = (type, e) => {
    console.log(type,e,'846666666666666666666');
    if(type == 'startTime'){
      setSDate(`${e.year()}-${e.month() + 1}-${e.date()}`);
      setStartDatetime(e.startOf('day').unix());

      //根据时区计算当前的时间戳
      cancelRef.current.sDate = `${e.year()}-${e.month() + 1}-${e.date()}`
      selectTime.current.st = e.startOf('day').unix()
      getUTCStartEnd()

    }
    if(type == 'endTime'){
      console.log('值为', e.endOf('day').unix())
      setEndDatetime(e.endOf('day').unix());
      setEDate(`${e.year()}-${e.month() + 1}-${e.date()}`);
      cancelRef.current.eDate = `${e.year()}-${e.month() + 1}-${e.date()}`
      selectTime.current.et = e.endOf('day').unix()
      getUTCStartEnd()
    }
    selectDate.current = true;
  }
  
  const toTurnPlan = () => {
    CommonDataCollect({
      action: 'track_Upgrade',
      event_type:'overlimit_pop'
    });
    // setTimeout(() => {
    //   window.location.href='/app/pricing?select=1'
    // },800)

    if([1,10, 4, 8,13,14,16,17,18,19,20].includes(plan?.type)){
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'Tracking')
    } 

  }
  const  renderTrackingTip = () =>{
    return ( <>
      { 
          [1,4,8,10].includes(plan.type) ? 
          <div className={styles.trackingTip}>
          <span>{intl.get('tracking.trackingTipFree2')}</span>
          <div onClick={toTurnPlan} className={styles.trackingBtn}>{intl.get('tracking.trackingTipFreeBtn')}</div>
          </div>  :
          <>
         {
         !trackStore && 
         <div className={styles.trackingTip}>
          <span>{intl.get('tracking.trackingTipPay')}</span>
          <a href='/app/settings?id=13'>{intl.get('tracking.trackingTipPayBtn')}</a>
          </div>
          }
          </>
      }
      </>
    )
  }

  const onChangeCustTime = (e) => {
    console.log(e.target.value,'9066666666666666');
    setDateRadioDefaultType(e.target.value)
    if(e.target.value == 6){
      selectDate.current = true
    }else{
      selectDate.current = false
    }
  }

  const getTimeDom = () => {
    // 本来一个RangePicker能搞定的  非得拆开俩个来写
    const dateFormat = 'YYYY-M-DD';
    // 时间弹层
    return <>
      <div className={classnames({
        [radioStyles.SearchBox]:true,
        [radioStyles.SearchBox_active]:true
      })} data-type="getTimeDom">
        <Radio.Group onChange={onChangeCustTime} defaultValue={dateRadioDefaultType}>
          {
            dataType.map(item => {
              return <Radio key={item.type} value={item.type}>{item.name}</Radio>
            })
          }
          <Radio key={9} value={6}>
            <div className={orderStyles.datePicker}>
              <div>Custom</div>
              <div className='DatePicker_box tracking'>
                <p className='form'>From</p>
                <DatePicker
                  disabled={dateRadioDefaultType == 6 ? false : true} 
                  defaultValue={moment(
                    moment(startDatetime * 1000).format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  )}
                  format={dateFormat}
                  onChange={e => {onChangeTime('startTime', e)}}
                />
                <span className='data_icon'>
                    <i
                      style={{ fontSize: 18, marginTop: '8px' }}
                      className="material-icons"
                    >
                      calendar_today
                    </i>
                  </span>
              </div>
              <div className='DatePicker_box tracking'>
                <p className='form'>To</p>
                <DatePicker
                  disabled={dateRadioDefaultType == 6 ? false : true} 
                  defaultValue={moment(
                    moment(endDatetime * 1000).format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  )}
                  format={dateFormat}
                  onChange={e => {onChangeTime('endTime', e)}}
                />
                <span className='data_icon'>
                  <i
                    style={{ fontSize: 18, marginTop: '8px' }}
                    className="material-icons"
                  >
                    calendar_today
                  </i>
                </span>
              </div>
            </div>
          </Radio>
        </Radio.Group>
      </div>
    </>
  
  }

  const getTimeZoneDom = () => {
    return <>
      <FilterSearch
        dataSource={timezoneKu}
        defaultValue={currentTimezone}
        gruopType="timeZone" 
        effectsAction={{
          onChangeTimeZone: (e) => {
            setCurrentTimezone(e)
            cancelRef.current.timeZone = e;
            getUTCStartEnd()
          },
        }} />
    </>
  }

  const getFulfillDom = () => {
    return <>
      <FilterSearch
        dataSource={[
          {
            id:5,
            title:intl.get('tracking.fulfilled')
          },
          {
            id:0,
            title:intl.get('tracking.unfulfilled')
          }
        ]}
        defaultValue={cancelRef.current.fulfilled}
        gruopType="fulfill" 
        effectsAction={{
          onChangeFuilfill: (e) => {
            cancelRef.current.fulfilled = e.target.value
          },
        }} />
    </>
  }

  let FilterHeaderOptions = [
    {
      key:1,
      render: searchFilterOrder('search')
    },
    {
      key:2,
      render: searchFilterOrder('filter')
    },
  ]

  let DrawerOptions = [
    {
      title:'Order Status',
      key:1,
      filterComponent: getFulfillDom()
    },
    {
      title:intl.get('order.order_title.date'),
      key:0,
      filterComponent: getTimeDom()
    },
    {
      title:intl.get('order.time_zone_placeholder'),
      key:2,
      filterComponent: getTimeZoneDom()
    },
  ]

  const onClearFilterComfig = () => {
    let trackAction = JSON.parse(localStorage.getItem('trackAction'))

    setStartDatetime(trackAction.startDatetime)
    setEndDatetime(trackAction.endDatetime)
    setCurrentTimezone(trackAction.timeZone)
    setDrawerVisiable(false)
    setDateRadioDefaultType(3)

    console.log(1058888888888);
    cancelRef.current.currentPage = 1;
    cancelRef.current.fulfilled = 5
    cancelRef.current.timeZone = trackAction.timeZone
    cancelRef.current.startDatetime = trackAction.startDatetime
    cancelRef.current.endDatetime = trackAction.endDatetime

    cancelRef.current.sDate = trackAction.sDate
    cancelRef.current.eDate = trackAction.eDate
    
    cancelFuilTag.current.fulfilled = 5

    cancelTime.current.sDate = trackAction.sDate
    cancelTime.current.eDate = trackAction.eDate

    cancelTimeZone.current.timeZone = trackAction.timeZone

    getTrackingList(false)
  }

  //确认按钮
  const confirmList = () => {
    cancelFuilTag.current.fulfilled = cancelRef.current.fulfilled
    cancelRef.current.currentPage = 1;

    let cancelData = JSON.parse(localStorage.getItem('trackAction'))
      let dataObj = dataType.find(item => item.type == dateRadioDefaultType)
    if(dateRadioDefaultType== 1){
      cancelTime.current.sDate = moment().startOf('day').format('YYYY-M-D')
      cancelTime.current.eDate = moment().endOf('day').format('YYYY-M-D')
    }
    if(dateRadioDefaultType== 2){
      cancelTime.current.sDate = moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').format('YYYY-M-D')
      cancelTime.current.eDate = moment().format('YYYY-M-D')
    }
    if(dateRadioDefaultType== 3){
      cancelTime.current.sDate = moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').format('YYYY-M-D')
      cancelTime.current.eDate = moment().format('YYYY-M-D')
      cancelTime.current.changeTime=true
      
    }
    if(dateRadioDefaultType== 4){
      cancelTime.current.sDate = moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').format('YYYY-M-D')
      cancelTime.current.eDate = moment().format('YYYY-M-D')
    }
    if(dateRadioDefaultType== 5){
      cancelTime.current.sDate = moment(cancelData.eDate).subtract(dataObj.timestamp, 'days').format('YYYY-M-D')
      cancelTime.current.eDate = moment().format('YYYY-M-D')
    }
    if(dateRadioDefaultType == 6){
      cancelTime.current.sDate = cancelRef.current.sDate
      cancelTime.current.eDate = cancelRef.current.eDate
    }


    cancelTimeZone.current.timeZone = cancelRef.current.timeZone
    cancelTime.current.dateRadioDefaultType = dateRadioDefaultType
    setCurrentPage(1)
    setDrawerVisiable(false)
    getTrackingList()
  }

  const clearTimeZone = () => {
    let trackAction = JSON.parse(localStorage.getItem('trackAction'))
    cancelTimeZone.current.timeZone = trackAction.timeZone
    cancelRef.current.timeZone = trackAction.timeZone
    setCurrentTimezone(trackAction.timeZone)
    getUTCStartEnd()
    getTrackingList()
  }
  
  const clearTime = () => {
    cancelTime.current.changeTime = false
    // console.log(11033333333);
    selectDate.current = false
    let trackAction = JSON.parse(localStorage.getItem('trackAction'))
    cancelRef.current.startDatetime = trackAction.startDatetime
    cancelRef.current.endDatetime = trackAction.endDatetime
    cancelTime.current.sDate = trackAction.sDate
    cancelTime.current.eDate = trackAction.eDate
    setDateRadioDefaultType(3)
    cancelTime.current.dateRadioDefaultType = 3;
    getTrackingList()
  }

  const selectTag = () => {
    let arr = [
      {
        id:5,
        title:intl.get('tracking.fulfilled')
      },
      {
        id:0,
        title:intl.get('tracking.unfulfilled')
      }
    ]
    
    let obj = arr.find(item => item.id == cancelFuilTag.current.fulfilled)

    let trackAction = JSON.parse(localStorage.getItem('trackAction'))

    let showTime;

    // 控制时间戳显示与隐藏
    if(cancelTime.current.dateRadioDefaultType == 1||cancelTime.current.dateRadioDefaultType == 2||cancelTime.current.dateRadioDefaultType == 4||cancelTime.current.dateRadioDefaultType == 5||cancelTime.current.dateRadioDefaultType == 6){
      showTime = true
    }else {

      if(cancelTime.current.changeTime){
              showTime = true
      }else{
              showTime = false;
      }
}
    // if(cancelTime.current.dateRadioDefaultType != 6){
    //   showTime = false
    // }else{
    //   showTime = true
    // }


    let showTimeZone;
    let timeZoneObj;
    if(!cancelTimeZone.current.timeZone){
      showTimeZone = false
    }else{
      if(cancelTimeZone.current.timeZone == trackAction.timeZone){
        showTimeZone = false
      }else{
        showTimeZone = true
        timeZoneObj = timezoneKu.find(item => item.value == cancelTimeZone.current.timeZone)
      }
    }

    return <div style={{marginTop:20, display:'flex'}}>
      <div className="flagsTagListItem" onClick={() => { setDrawerVisiable(true) }}>
        <p>Order Status: {obj.title}</p>
      </div>
      {
        showTimeZone ? <div className="flagsTagListItem">
        <p onClick={() => { setDrawerVisiable(true) }}>{timeZoneObj?.label}</p>
        <div
            onClick={clearTimeZone}
          >
            <img src="https://img.dsers.com/images/order-flags-icon/close.png"></img>
          </div>
        </div> : null
      }
      {
        showTime ? <div className="flagsTagListItem">
        <p onClick={() => { setDrawerVisiable(true) }}>{cancelTime.current.sDate} ~ {cancelTime.current.eDate} </p>
        <div
            onClick={clearTime}
          >
            <img src="https://img.dsers.com/images/order-flags-icon/close.png"></img>
          </div>
        </div> : null
      }
    </div>
  }

  const inputDom = () => {
    // searchFlag
    return <div className={hidden ? 'input_trastion' : 'input_trastion_active'}>  
      <div id="order_no_input_box">
        <div className={searchFlag ? 'order_no_input_box order_no_input_box_active' : 'order_no_input_box'}>
            <div className="order_no_input_box_clearIcon">
            <div className='order_no_input_box_clearIcon_i'>
              {
                deleteSearch ? <Icon
                type="close-circle"
                theme="filled"
                className={styles.deleteIco}
                onClick={e => {
                  e.stopPropagation();
                  deleteSearchKey();
                }}
                onMouseEnter={e => {
                  e.stopPropagation();
                  showDeleteSearch();
                }}
              /> : null
              }
              
            </div>

              <Input
                addonBefore={searchMoreFilter()}
                className={styles.search}
                value={searchKey}
                allowClear={false}
                placeholder={intl.get('tracking.search_input_placeholder')}
                onChange={e => changeSearchKey(e)}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    changeSearch();
                  }
                }}
                ref={inputRef}
                onMouseEnter={e => {
                  e.stopPropagation();
                  showDeleteSearch();
                }}
                onMouseLeave={e => {
                  e.stopPropagation();
                  hideDeleteSearch();
                }}
              />

              <button onClick={changeSearch} style={searchFlag ? {display:'block'} : {display:'none'}}>
                <i class="material-icons notranslate">search</i>
              </button>
            </div>
          </div>
      </div>
    </div>
  }

  return (
    <div className={styles.trackingContent} id="trackingBox">
      <div style={{marginTop:40}}>
        <FilterHeader title={intl.get('tracking.title')} options={FilterHeaderOptions} renderInput={inputDom()} />
      </div>
      
      <div className="flagsTagList">
        {selectTag()}
      </div>
      {renderTrackingTip()}
      <Tabs
        activeKey={`${tabsType}`}
        onChange={e => {
          setTrackingList([]);
          setCurrentPage(1);
          setTabsType(e);
        }}
      >
        {trackingMap.map(ele => {
          return (
            <TabPane key={`${ele[0]}`} tab={getOrderTabTitle(ele)}>
              {
                trackingList.length ? <List
                itemLayout="horizontal"
                dataSource={trackingList}
                className={styles.listContent}
                loading={loadingState}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <div className={styles.left}>
                      <div>
                        {intl.get('tracking.search_type.order_no')}
                        <a
                          href={`https://${getStores(
                            item.store_id
                          )}/admin/orders/${item.shopifyId}`}
                          target="_blank"
                        >
                          {item.orderNumber}
                        </a>
                      </div>
                      <div>
                        {intl.get('tracking.search_type.date')}
                        {item.date}
                      </div>
                      <div>
                        {item.aeID2.map((element, index) => (
                          <div key={index}>
                            <img src={element?.supply_platform == 2 ? iconAe : iconTmall} alt="iconAe" />
                            {
                              element?.supply_platform == 2 ? <a
                                href={`https://trade.aliexpress.com/order_detail.htm?spm=&orderId=${element?.id}`}
                                target="_black"
                              >
                                {element?.id}
                              </a> : <>{element?.id}</>
                            }
                            
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className={styles.content}>
                      <div className={styles.map}>
                        {item.aeData.map((element, index) => (
                          <div key={index}>
                            <div>
                              {intl.get('tracking.search_type.tracking_no')}
                              <Tooltip
                                placement="top"
                                title={
                                  (element?.trackingNo &&
                                    element.trackingNo[0] &&
                                    element.trackingNo[0].logistics_no) ||
                                  'No tracking number'
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className={classnames(styles.cursorDefault, {
                                    [styles.cursorRevert]:
                                      element?.trackingNo &&
                                      element?.trackingNo[0] &&
                                      element?.trackingNo[0].logistics_no
                                  })}
                                  onClick={() =>
                                    element?.trackingNo &&
                                    element.trackingNo[0] &&
                                    element.trackingNo[0].logistics_no
                                      ? openClose(
                                          item,
                                          element.trackingNo[0].logistics_no
                                        )
                                      : null
                                  }
                                >
                                  {(element?.trackingNo &&
                                    element.trackingNo[0] &&
                                    element.trackingNo[0].logistics_no) ||
                                    'No tracking number'}
                                </a>
                              </Tooltip>
                            </div>
                            {moreTracking(item, element?.trackingNo)}
                            <Tooltip placement="top" title={element.title}>
                              <div>{element.title}</div>
                            </Tooltip>
                            <div>
                              {intl.get('tracking.variants')}
                              <Tooltip
                                placement="top"
                                title={`${element.variant_title} * ${element.quantity}`}
                              >
                                {element.variant_title} * {element.quantity}
                              </Tooltip>
                            </div>
                            {
                              element?.supply_platform == 2 ?  <div>
                                {intl.get('tracking.shop')}
                                <Tooltip
                                  placement="top"
                                  title={element.store_name}
                                >
                                  <a href={element.store_url} target="_black">
                                    {element.store_name}
                                  </a>
                                </Tooltip>
                              </div> : null
                            }
                            
                            {element.map_type === 3 && element?.supply_platform == 2 ? (
                              <div
                                className={classnames(styles.mappingTag, {
                                  [styles.green]: !element.is_default
                                })}
                              >
                                BOGO
                              </div>
                            ) : null}
                            {element.map_type === 4 && element?.supply_platform == 2 ? (
                              <div
                                className={classnames(
                                  styles.mappingTag,
                                  styles.green
                                )}
                              >
                                Bundle
                              </div>
                            ) : null}
                            {element.attention === 1 && element?.supply_platform == 2 ? (
                              <div className={styles.err}>
                                Not shipped more than 7 days
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                      <div>
                        <Tooltip placement="top" title={item.orderStatus}>
                          <div className={styles.tag}>{item.orderStatus}</div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className={styles.right}>
                      <div>
                        {intl.get('tracking.search_type.customer_name')}
                        <Tooltip placement="top" title={item.contact_person}>
                          {item.contact_person}
                        </Tooltip>
                      </div>
                      <Tooltip placement="top" title={item.email}>
                        <div>
                          {intl.get('tracking.search_type.email')}
                          {item.email}
                        </div>
                      </Tooltip>
                      <Tooltip placement="top" title={item.address}>
                        <div>
                          {intl.get('tracking.search_type.address')}
                          {item.address}
                        </div>
                      </Tooltip>
                      <Tooltip placement="top" title={item.position}>
                        <div>{item.position}</div>
                      </Tooltip>
                    </div>
                  </List.Item>
                )}
              /> : <div className='track_no_data_box'>
                <div className='track_no_data_box_l'>
                   <p className='track_no_data_box_l_title'>Accurately know the tracking status.</p>
                   <div className='track_no_data_box_l_div'>
                    <p>1. Timely access to package status.</p>
                    <p>2. Reduce delivery delays.</p>
                    <p>3. Give feedback to customers in time.</p>
                   </div>
                </div>
                <div className='track_no_data_box_r'>
                    <img src="https://img.dsers.com/img/tracking_no_data.png"></img>
                </div>
              </div>
              }
              
            </TabPane>
          );
        })}
      </Tabs>

      <LogisticsDetails
        trackingNo={trackingNo}
        trackingData={trackingData}
        onClose={handleClose}
        getStores={getStores}
      />

      <OnlyPagination
        len={listLength}
        pageSize={pageSize}
        currentPage={currentPage}
        changePageSize={changePageSize}
        changePager={changePager}
        count={count}
      />
      <FilterDrawer
        defaultActiveKey={[0,1,2]}
        destroyOnClose={true}
        onClose={() => {
          setDrawerVisiable(false)
        }}
        visible={drawerVisiable} 
        title={intl.get('order.order_title.title')}
        options={DrawerOptions}
        onConfirm={confirmList}
        onCancel={onClearFilterComfig}
      />
    </div>
  );
};

const mapStateToProps = ({ login, global,dropshippersetting,user }) => ({
  userInfo: login.userInfo,
  storeId: global.storeIdWithOrder,
  storeIdWithOrder: global.storeIdWithOrder,
  timezoneRegion: global.timezoneRegion,
  trackingListProp:dropshippersetting.trackingList,
  plan: user.plan
});

export default connect(mapStateToProps)(Tracking);
