import React, { useState, useCallback, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { connect } from 'dva';
import {
  Button,
  Modal,
  Select,
  Icon,
  Progress,
  Spin,
  Alert,
  Tooltip
} from 'antd';

import styles from './LogisticsDetails.less';

import { setTracking, logisticsStatus, IconDisplayStyles } from '../util';

import { Get, Put, Post } from '../../../utils/request';

const { Option } = Select;

const LogisticsDetails = ({
  trackingNo,
  onClose,
  countDown,
  countDownButtonDisabled,
  trackingData,
  getStores,
  dispatch
}) => {
  const [loading, setLoading] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState({});
  const [legend, setLegend] = useState([]);
  const [selectTracking, setSelectTracking] = useState(undefined);
  const [retrackErr, setRetrackErr] = useState(false);
  const [noTrack, setNoTrack] = useState(false);

  // 计算七个icon展示样式
  const IconDisplayStyle = status => {
    let iconStatus = JSON.parse(JSON.stringify(IconDisplayStyles));
    if ([1, 2, 4, 6].includes(status)) {
      iconStatus.forEach((item, index) => {
        if (item.id === status) {
          item.status = 3;
          for (let i = 0; i < index; i += 1) {
            iconStatus[i].status = 3;
          }
          if (index !== 6) iconStatus[index + 1].status = 2;
        }
      });
    } else if ([5, 7].includes(status)) {
      for (let i = 0; i < 5; i += 1) {
        iconStatus[i].status = 3;
      }
      iconStatus[4 + 1].status = 4;
    } else if (status === 3) {
      for (let i = 0; i < 3; i += 1) {
        iconStatus[i].status = 3;
      }
      iconStatus[2 + 1].status = 4;
    }
    setLegend(iconStatus);
  };

  // 重新获取物流状态
  const retrack = async () => {
    // 点击retrack按钮  --- 打点
    window.DSERS_COLLECT({
      action:'logistics_tracking',
      custom_info: [{
        name:'CLICK_BUTTON_TRACKING',
        value:'retrack_click'
      }]
    });
    setLoading(true);
    setSelectTracking(undefined);
    try {
      let res = await Post(
        window.DSERS_API_CONFIG.HOST +
          `/tracking/api/v1/tracking_infos/${trackingDetails.id}/retrack`,
        {
          isCustomApi: true
        }
      );
      if (res?.code === 2000 && res.data) {
        setTrackingDetails(res.data);
        IconDisplayStyle(res?.data?.status);
        if ([2, 4, 6, 5, 7, 8].includes(res?.data?.status)) {
          setSelectTracking(res?.data?.status);
        }
        setRetrackErr(false);
        setNoTrack(false);
      }

      if (
        res?.code === 5500 &&
        res.data &&
        res.data['17TrackErrorCode'] == '-18019902'
      ) {
        setRetrackErr(true);
      }
      if (
        res?.code === 5500 &&
        res.data &&
        res.data['17TrackErrorCode'] == '-18019909'
      ) {
        setNoTrack(true);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: 'global/countDown',
      payload: false
    });
    setLoading(false);
  };

  // 修改当前物流状态
  const setTrackingData = async e => {
    setSelectTracking(e);
    try {
      const res = await Put(
        window.DSERS_API_CONFIG.HOST +
          `/tracking/api/v1/tracking_infos/${trackingDetails.id}`,
        {
          data: { status: e },
          isCustomApi: true
        }
      );
      if (res?.code === 2000) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 获取物流详情
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Get(
        window.DSERS_API_CONFIG.HOST +
          `/tracking/api/v1/tracking_details?mailNo=${trackingNo}`,
        {
          isCustomApi: true
        }
      );
      if (res?.code === 2000 && res.data) {
        setTrackingDetails(res.data);
        IconDisplayStyle(res?.data?.status);

        if ([2, 4, 6, 5, 7, 8].includes(res?.data?.status)) {
          setSelectTracking(res?.data?.status);
        }
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
    setLoading(false);
  };

  // 进度条样式
  const progressPercent = val => {
    let percent;
    let strokeColor;
    switch (val) {
      case 1:
        percent = 0;
        strokeColor = '#4caf50';
        break;
      case 2:
        percent = 50;
        strokeColor = '#4caf50';
        break;
      case 3:
        percent = 100;
        strokeColor = '#4caf50';
        break;
      case 4:
        percent = 50;
        strokeColor = '#F44436';
        break;
      default:
        break;
    }
    return (
      <Progress percent={percent} showInfo={false} strokeColor={strokeColor} />
    );
  };

  useEffect(() => {
    setSelectTracking(undefined);
    setLegend([]);
    setRetrackErr(false);
    setNoTrack(false);
    setTrackingDetails({});
    if (trackingNo) {
      fetchData();
    }
  }, [trackingNo]);

  return (
    <>
      <Modal
        visible={!!trackingNo}
        footer={null}
        wrapClassName={styles.content}
        width={868}
        onCancel={onClose}
        destroyOnClose={true}
      >
        <Spin size="large" spinning={loading}>
          <div className={styles.title}>
            <div className={styles.logisticsNumber}>
              {trackingDetails?.mailNo}
            </div>
            <div className={styles.state}>
              {intl.get('tracking.logisticsStatus')[trackingDetails?.status]}
            </div>
          </div>

          <div className={styles.logisticsInformation}>
            <div className={styles.orderNo}>
              {intl.get('tracking.search_type.order_no')}
              <a
                href={`https://${getStores(
                  trackingData.store_id
                )}/admin/orders/${trackingData.shopifyId}`}
                target="_blank"
              >
                {trackingData.orderNumber}
              </a>
            </div>
            <div className={styles.time}>{trackingData.date}</div>
          </div>

          <div className={styles.operation}>
            <div className={styles.title}>{intl.get('tracking.manually')}</div>
            <Select
              suffixIcon={<Icon type="caret-down" />}
              style={{ width: 120 }}
              onChange={setTrackingData}
              value={selectTracking}
              placeholder={intl.get('tracking.select')}
            >
              {intl.get('tracking.setTracking').map(item => (
                <Option value={item.key} key={item.key}>
                  <Tooltip placement="top" title={item.value}>
                    {item.value}
                  </Tooltip>
                </Option>
              ))}
            </Select>
          </div>

          {trackingDetails.status !== 8 && !retrackErr && !noTrack ? (
            <div className={styles.legend}>
              <div>
                <img
                  src="https://img.dsers.com/shopify/tracking/logisticsDetailsBox/pending.png"
                  alt="pending"
                  className={classnames({
                    [styles.imgStatus]: legend[0]?.status > 1
                  })}
                />
                <div className={styles.describe}>
                  {intl.get('tracking.pending')}
                </div>
              </div>
              <div>{progressPercent(legend[1]?.status)}</div>
              <div>
                <img
                  src="https://img.dsers.com/shopify/tracking/logisticsDetailsBox/InTramsit.png"
                  alt="InTramsit"
                  className={classnames({
                    [styles.imgStatus]: legend[2]?.status > 1
                  })}
                />
                <div className={styles.describe}>
                  {intl.get('tracking.Intramsit')}
                </div>
              </div>
              <div>{progressPercent(legend[3]?.status)}</div>
              <div>
                <img
                  src="https://img.dsers.com/shopify/tracking/logisticsDetailsBox/pickUp.png"
                  alt="pickUp"
                  className={classnames({
                    [styles.imgStatus]: legend[4]?.status > 1
                  })}
                />
                <div className={styles.describe}>
                  {intl.get('tracking.pickup')}
                </div>
              </div>
              <div>{progressPercent(legend[5]?.status)}</div>
              <div>
                <img
                  src="https://img.dsers.com/shopify/tracking/logisticsDetailsBox/delivered.png"
                  alt="delivered"
                  className={classnames({
                    [styles.imgStatus]: legend[6]?.status > 1
                  })}
                />
                <div className={styles.describe}>
                  {intl.get('tracking.delivered')}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.alertStyle}>
              <Alert
                showIcon={true}
                icon={
                  <span>
                    <i
                      class="material-icons"
                      style={{
                        color: '#FF8F00',
                        marginTop: '-4px',
                        marginLeft: '-3px'
                      }}
                    >
                      error_outline
                    </i>
                  </span>
                }
                type="warning"
                message={
                  <div>
                    {!noTrack ? (
                      <div>
                        {intl.get('tracking.unrecognizedErr')}{' '}
                        {trackingDetails.status === 8
                          ? intl.get('tracking.tips')
                          : ''}
                      </div>
                    ) : (
                      intl.get('tracking.noTrack')
                    )}
                  </div>
                }
              />
            </div>
          )}

          {trackingDetails.status !== 8 && !retrackErr && !noTrack ? (
            <div className={styles.detailedInformation}>
              {trackingDetails?.secondServiceDetails ? (
                  <div>
                    {trackingDetails?.secondServiceDetails?.map(
                      (item, index) => (
                        <div key={index}>
                          {item.time} {item.location} {item.desc}
                        </div>
                      )
                    )}
                  </div>
              ) : null}
            </div>
          ) : null}


        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ global }) => ({
  countDown: global.countDown,
  countDownButtonDisabled: global.countDownButtonDisabled
});

export default connect(mapStateToProps)(LogisticsDetails);
